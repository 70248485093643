:root {
    /*GENERAL COLORS*/
    --obos-blue: #2a4d96;
    --obos-green: #278056;
    --white: #ffffff;
    --yellow: #b08011;
    --red: #941715;
    --grey: #696969;

    --backround-white-blue: #fafafa;
    --font-blue: #004F9F;
    --black: #1f1f1f;

    --light-grey: #c5c5c5;
    --light-blue: #dce9f4;
    --light-yellow: #fef4cc;
    --light-orange: #ffcc94;
    --light-red: #ffd0cf;
    --light-green: #c4ead5;

    --dark-yellow: #775504;
    --dark-blue: #182b5b;
    --dark-green: #184d34;
    --dark-red: #600E0C;

    --disabled-grey: #5c5c5c;
    --disabled-green: #618A7A;
    --disabled-green-font: #9ABEB0;
    --disabled-blue: #717d9c;
    --disabled-blue-light: #c6cad6;
    --disabled-red: #873a39;
    --disabled-red-font: #c58e8d;

    --circle-red: var(--red);
    --circle-red-opacity: rgba(148, 23, 21, 0.4);
    --circle-orange: #ca6500;
    --circle-orange-opacity: rgba(202, 101, 0, 0.4);
    --circle-green: var(--obos-green);
    --circle-green-opacity: rgba(39, 128, 86, 0.4);


    --homeownerAssoc-border:#007B7F;
    --homeownerAssoc:#009196;
    --homeownerAssoc-opacity: rgba(0, 145, 150, 0.4);
}