@import url('font.css');
@import url('colors.css');
@import url('icons.css');
@import url('buttons.css');

* {
    margin: 0;
    padding: 0;
}

body {
    width: 100vw;
    overflow-x: hidden;
}

#root {
    margin: 0;
    height: 100vh;
    color: var(--black);
}

.leaflet-control-attribution {
    display: none;
}

/*------------------------------------------------------------------------------------GENERAL STYLES STARTS*/
/*CONTAINS*/
/*1. SCROLLBAR*/
/*2. SHADOW*/
/*3. FLEX*/
/*4. ALIGN*/
/*5. WIDTHS*/
/*6. HEIGHTS*/
/*7. MARGINS*/
/*8. PADDING*/
/*9. DISPLAY*/
/*10. BORDERS*/
/*11. INPUTS*/

/*1. SCROLLBAR STARTS*/
::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    display: none;
}

/* handle */
::-webkit-scrollbar-thumb {
    background: var(--obos-blue);
    border-radius: 6px;
}
/*1. SCROLLBAR ENDS*/
/*2. SHADOW STARTS*/
.header,
.login--content,
.info-banner,
.helping-box,

.map--filter,
.map--filter-entire-box,
.map--filter-toggle .switch .slider,
.map--search .input, .map--search-address .input, .map--add-ports-display,
.map--add,
.map--help,
.choose-port--chosen-ports,

.list--closeable,
.list--content-container,
.list--statistics,

.info-page--content-header .subscribe.disabled .info-text,
.info-page--name svg,
.info-page--name-input svg,
.info-page--content,
.info-page--log,
.info-page--data,
.info-page--files,
.info-page--files-fullscreen-container img,
.info-page--files-fullscreen-container .pdf,
.info-page--files-fullscreen-buttons,
.info-page--connections,
.info-page--switches,
.info-page--connections-nodes,

.chat--all,
.chat--input-container,
.chat--help-box,
.chat--session:hover,
.chat--session.chosen,
.chat--unread-switch .switch,
.chat--message .message,
.chat--search-box,
.chat--no-open-chat,
.chat--session .chat--session-icons .icon--new-message,
.chat--session .chat--session-icons .chat--hour-since,
.chat--no-messages,
.chat--users-search,
.chat--no-matches,

.popup-opacity-background .delete-popup-content,
.popup-opacity-background .choose-port--message,

.profile--map,
.profile--user img,
.profile--nav,
.profile--dashboard--notifications,
.profile--dashboard--notifications-big-screen,
.profile--dashboard--updates .profile--dashboard-update,
.profile--dashboard--notifications .profile--dashboard-notification,
.profile--dashboard--notifications-big-screen .profile--dashboard-notification,
.profile--subcriptions-buttons svg,
.profile--subscription,
.subscription-none,
.profile--admin-handle-data-closeable-container, .profile--admin-handle-data-note,

.info-message,
input, select, textarea, .map--add-search-display,
.btn, .btn-popup,
.profile--admin-all-users .user,
.feedback--data .feedback {
    box-shadow: 2px 5px 7px rgba(0, 0, 0, 0.2);
}
/*2. SHADOW ENDS*/
/*3. FLEX START*/
.d-flex {
    display: flex;
}

.flex--column {
    display: flex;
    flex-direction: column;
}

.flex--row {
    display: flex;
    flex-direction: row;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-se {
    justify-content: space-evenly;
}

.justify-content-sb {
    justify-content: space-between;
}

.justify-content-right {
    justify-content: flex-end;
}

.justify-content-left {
    justify-content: left;
}

/*3. FLEX ENDS*/
/*4. ALIGN STARTS*/

.align-text--left{
    text-align: left;
}

.align-text--center {
    text-align: center;
}

.align-items--center {
    align-items: center;
}

.align-items--left {
    align-items: flex-start;
}

/*4. ALIGN ENDS*/
/*5. WIDTHS STARTS*/
.w-22vw {
    width: 22vw;
}

.w-25vw {
    width: 25vw;
}

.w-75vw {
    width: 75vw;
}

.w-78vw {
    width: 78vw;
}

.w-50vw {
    width: 50vw;
}

.w-100vw {
    width: 100vw;
}

.w-100per {
    width: 100%;
}

.w-620px {
    width: 620px;
}
/*5. WIDTHS ENDS*/
/*6. HEIGHTS STARTS*/

.h-auto {
    height: auto;
}

.h-150px {
    height: 150px;
}

.h-175px {
    height: 175px;
}

.h-10vh {
    height: 10vh;
}

.h-20vh {
    height: 20vh;
}

.h-90vh {
    height: 90vh;
}

.h-100vh {
    height: 100vh;
}

/*6. HEIGHTS ENDS*/
/*7. MARGINS STARTS*/

.m-b-0 {
    margin-bottom: 0 !important;
}

.m-b-10px {
    margin-bottom: 10px;
}

.m-b-20px {
    margin-bottom: 20px;
}

.m-b-30px {
    margin-bottom: 30px;
}

.m-10px {
    margin: 10px;
}

.m-l-10px {
    margin-left: 10px;
}

.m-l-20px {
    margin-left: 20px;
}

.m-l-40px {
    margin-left: 40px;
}

.m-l-50px {
    margin-left: 50px;
}

.m-r-0 {
    margin-right: 0 !important;
    margin-left: auto;
}

.m-r-10px {
    margin-right: 10px;
}

.m-r-20px {
    margin-right: 20px;
}

.m-t-10px {
    margin-top: 10px;
}

.m-t-30px {
    margin-top: 30px;
}

.m-t-40px {
    margin-top: 40px;
}

.m-s-auto {
    margin-right: auto;
    margin-left: auto;
}

/*7. MARGINS ENDS*/
/*8. PADDING STARTS*/

.p-30px {
    padding: 30px;
}

.p-50px {
     padding: 50px;
}

.p-tb-100px {
    padding-top: 100px;
    padding-bottom: 100px;
}

/*8. PADDING ENDS*/
/*9. DISPLAY STARTS*/

.d-none {
    display: none;
}

.position-fixed {
    position: fixed;
}

.overflow-hidden {
    overflow: hidden;
}

/*9. DISPLAY ENDS*/
/*10. BORDER STARTS*/

.b-r-blue {
    border-right: solid 3px var(--obos-blue);
}
.b-b-blue {
    border-bottom: solid 3px var(--obos-blue);
}

.b-t-blue {
    border-top: solid 3px var(--obos-blue);
}

.b-tb-blue {
    border-top: solid 3px var(--obos-blue);
    border-bottom: solid 3px var(--obos-blue);
}

.b-grey {
    border: 3px solid var(--light-grey);
}

.b-white {
    border: 3px solid var(--white);
}

.b-green {
    border: 3px solid var(--obos-green);
}

.br {
    border-radius: 20px;
}
/*10. BORDERS ENDS*/
/*11. INPUTS STARTS*/
label {
    margin-bottom: 4px;
}

::-webkit-input-placeholder {
    font-style: italic;
}

input:-webkit-autofill {
    background-color: white;
    transition: background-color 5000s ease-in-out 0s
}

input[type="text"],
input[type="number"],
input[type="password"],
.input,
textarea {
    box-sizing: border-box;
    height: 50px;
    border-radius: 20px;
    border: 3px solid var(--white);
    padding-left: 10px;
    background-color: var(--white);
    margin-bottom: 20px;
    resize: none;
}

input[type="checkbox"] {
    cursor: pointer;
    appearance: none;
    background-color: var(--white);
    margin: 15px 15px 15px 0;
    width: 22px;
    height: 22px;
    border: 3px solid var(--white);
    border-radius: 0.15em;
    display: grid;
    place-content: center;
}

input[type="checkbox"]:not(:checked):hover::before {
    content: url('~../icons/checkmarks/Checkmark_nb_green.svg');
    width: 30px;
    height: 30px;
}

input[type="checkbox"]:checked:hover::before {
    content: none;
}

input[type="checkbox"]:hover::before {
    content: url('~../icons/checkmarks/Checkmark_nb_blue.svg');
    width: 30px;
    height: 30px;
}

input[type="checkbox"]:checked::after {
    content: url('~../icons/checkmarks/Checkmark_nb_blue.svg');
    width: 30px;
    height: 30px;
}

input[type="checkbox"]:checked:hover::after {
    content: url('~../icons/checkmarks/Checkmark_nb_dark_blue.svg');
}

select {
    box-sizing: border-box;
    height: 50px;
    padding-left: 10px;
    margin-bottom: 20px;
    border-radius: 20px;
    border: 3px solid var(--white);
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="number"]:focus,
select:focus,
textarea:focus {
    border: 3px solid var(--obos-blue) !important;
    outline: none;
}

.switch {
    position: relative;
    display: inline-block;
    min-width: 45px;
    max-width: 45px;
    height: 26px;
}
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: var(--obos-green);
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 34px;
}
/*11. INPUTS ENDS*/
/*------------------------------------------------------------------------------------GENERAL STYLES ENDS*/
/*------------------------------------------------------------------------------------HEADERS STARTS*/
.header--nli, .header {
    height: 90px;
}
.header--nli {
    flex: 1;
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 30px;
    padding-right: 30px;
    background-color: var(--white);
    z-index: 1000;
}

.header--obos-logo {
    height: 55px !important;
    width: auto;
    padding-right: 10px;
    border-right: 3px solid var(--obos-blue);
    margin: 0 10px 0 0 !important;
}

.header--spots h3, .header--spots p {
    display: flex;
    align-items: center;
    justify-content: center;
}

.header--spots h3 {
    color: var(--obos-blue);
    font-size: 22px;
    height: 22px;
}
.header--spots p {
    height: 14px !important;
}

.header--spots-logo {
    height: 40px !important;
}

.header--nli p, .header p {
    height: 14px;
    margin-top: 0;
    font-size: 14px;
    color: var(--font-blue);
}

.header {
    padding-left: 30px;
    padding-right: 30px;
    background-color: var(--white);
    position: relative;
    z-index: 1000;
}

.header--buttons {
    width: 40%;
}

.header--buttons a {
    height: 20px;
    color: var(--obos-blue);
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.header--buttons a svg {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    transition: transform 0.3s ease;
    filter: brightness(100%);
}

.header--buttons a:hover .icon--chat-unread {
    filter: unset !important;
    background-image: url('~../icons/header/Chat_unread_blue-dark.svg');
}

/*HOVER*/

.header--buttons a:hover {
    color: var(--dark-blue);
}

.header--buttons a:hover svg {
    filter: brightness(50%)
}

/*SPESIFIC ICON ANIMATIONS*/
.header--buttons a:hover .icon--compass {
    transform: rotate(180deg);
}

@keyframes wiggle {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(10deg); }
    50% { transform: rotate(-10deg); }
    75% { transform: rotate(10deg); }
    100% { transform: rotate(0deg); }
}

.header--buttons a:hover .icon--profile {
    animation: wiggle 0.5s ease;
}
/*------------------------------------------------------------------------------------HEADERS ENDS*/
/*------------------------------------------------------------------------------------MAP PAGE STARTS*/
/*CONTAINS*/
/*1. MAP PAGE GENERAL*/
/*2. MAP FILTER*/
/*2.1 REFRESH MAP BUTTON*/
/*2.2 TOGGLE ADDRESS OBJECTS BUTTON*/
/*2.3 SEARCH */
/*2.4 CHECKBOXES*/
/*3. MAP ITSELF*/
/*4. MAP POPUPS*/
/*5. MAP ADD*/
/*5.1 NAV*/
/*5.1 FORM*/
/*6. MAP UPDATE BUTTONS*/

/*1. MAP PAGE GENERAL*/
.map {
    flex: 1;
    height: calc(100vh - 10vh);
}
/*MAP PAGE GENERAL ENDS*/

/*2. MAP FILTER STARTS*/
.map--filter {
    box-sizing: border-box;
    background-color: var(--obos-blue);
    color: var(--white);
    height: 92vh;
    width: 25vw;
    position: absolute;
    z-index: 1;
    overflow-y: scroll;
    border-radius: 0 20px 0 0;
}

.map--filter::-webkit-scrollbar {
    display: none;
}

.map--filter-comps {
    width: 100%;
}

/*2.1 REFRESH MAP BUTTON*/
.map--filter .refresh-button {
    width: 200px;
}

.map--filter .refresh-button:hover {
    border: 3px solid var(--white);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.map--filter .refresh-button svg.spin {
    animation: spin 1s linear infinite;
}

/*2.2 TOGGLE ADDRESS OBJECTS BUTTON*/
.map--filter-comps h3, .map--filter-toggle .switch {
    margin-bottom: 10px;
}

.map--filter-toggle h3 {
    margin-right: 10px;
    width: 175px;
}

/*2.3 SEARCH*/
.map--search, .map--search-address {
    margin-bottom: 15px;
    width: 100%;
}

.map--search input, .map--search-address input {
    all: unset;
    max-width: 250px;
    min-width: 100px;
    height: 50px;
    border: unset;
    width: 100%;
}

.map--search .input, .map--search-address .input {
    border-radius: 30px;
    height: 50px;
    box-sizing: border-box;
    width: 100%;
}

.map--search .input input:focus, .map--search-address .input input:focus {
    outline: none !important;
    border: none !important;
}

.map--search .input,
.map--search-address .input,
.map--search-results {
    color: var(--black);
    margin-bottom: 20px;
    min-width: 150px;
    background-color: var(--white);
}

.map--search .input svg,
.map--search-address .input svg{
    min-width: 30px;
    min-height: 30px;
    max-width: 30px;
    max-height: 30px;
    border: 3px solid var(--white);
    border-radius: 100%;
}

.map--search-results {
    padding: 10px;
    border-radius: 25px;
    max-height: 575px;
}

.map--search-results .results-header p:nth-child(2) {
    color: var(--obos-green);
    font-weight: 700;
}

.map--search-results .results-header {
    margin-bottom: 5px;
}

.results-responses {
    max-height: 525px;
    overflow-y: scroll;
}

.map--search-results .map--search-result {
    margin-bottom: 5px;
    text-decoration: none;
    color: var(--black);
}

.map--search-result div {
    width: fit-content;
}

.map--search-results a {
    color: var(--black);
    text-decoration: none;
}

.map--search-results a:hover, .map--search-results p:hover:not(.results-header p) {
    color: var(--obos-green);
    font-weight: bold;
    cursor: pointer !important;
}

/*2.4 CHECKBOXES*/
.map--filter-checkboxes-container {
    height: calc(100% - 300px);
    width: 100%;
    margin-bottom: 15px;
}

.map--filter-checkboxes-container::-webkit-scrollbar {
    display: none;
}

.map--filter-entire-box {
    box-sizing: border-box;
    min-height: 50px;
    margin-bottom: 15px;
    width: 100%;
    border-radius: 25px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
    padding-right: 5px;
}

.map--filter-entire-box .map--filter--checkbox {
    min-height: 0;
    max-height: 1000px;
    overflow: hidden;
    transition: max-height 1.0s ease-in-out;
}

.map--filter-entire-box h4 {
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.map--filter-entire-box h4 svg {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    transform: rotate(0deg);
    transition: transform 0.5s ease-in-out;
}

.map--filter--checkbox label {
    cursor: pointer;
    color: var(--white) !important;
    font-weight: 500 !important;
}

.map--help {
    box-sizing: border-box;
    width: 100%;
    background-color: var(--white);
    border-radius: 25px;
    padding: 10px;
    color: var(--black);
    max-height: 50vh;
    overflow-y: scroll;
}

.map--help-text1 {
    margin-bottom: 10px;
}

.map--help-text1 p {
    width: calc(100% - 50px);
    margin-left: 10px;
}

.map--help-icons {
    width: 100%;
    margin-top: 10px;
}

.map--help-icons div {
    width: 100px;
}

.map--help-circle {
    box-sizing: border-box;
    height: 40px !important;
    width: 40px !important;
    border-radius: 50%;
}
.map--help-circle.blue {
    border: solid 3px var(--homeownerAssoc-border);
    background-color: var(--homeownerAssoc-opacity);
}
.map--help-circle.green {
    border: solid 3px var(--circle-green);
    background-color: var(--circle-green-opacity);
}
.map--help-circle.orange {
    border: solid 3px var(--circle-orange);
    background-color: var(--circle-orange-opacity);
}
.map--help-circle.red {
    border: solid 3px var(--circle-red);
    background-color: var(--circle-red-opacity);
}
/*2.4 CHECKBOXES ClOSE STATE*/

.map--filter-entire-box.close svg {
    transform: rotate(-180deg);
    transition: transform 0.5s ease-in-out;
}

.map--filter-entire-box.close .map--filter--checkbox {
    max-height: 0;
    transition: max-height 1.0s ease-in-out;
}

.map--filter-entire-box.close:hover {
    background-color: var(--dark-blue);
}
/*MAP FILTER ENDS*/

/*3. MAP ITSELF STARTS*/
.big-map {
    position: absolute;
    height: calc(106vh - 90px);
    width: 77vw;
    z-index: 0;
    top: 90px;
    left: 23vw;
    right: 0;
    bottom: 0;
}

.big-map .leaflet-control-zoom {
    display: none;
}
/*MAP ITSELF ENDS*/

/*4. MAP POPUPS STARTS*/
.leaflet-popup-content-wrapper {
    background-color: var(--white) !important;
    width: 400px;
    height: fit-content;
}

.leaflet-popup-tip {
    background: var(--white) !important;
}

.leaflet-popup-close-button span {
    color: var(--black);
    font-weight: bold;
    font-size: 20px;
    padding: 10px 10px 0 0;
}

.map-popup-header {
    width: 100%;
    margin-bottom: 10px;
}

.map-popup-header h4 {
    font-weight: 700;
}

.map--popup svg {
    width: 35px;
    height: 35px;
    margin-right: 10px;
}

.map--popup-body  {
    width: 350px !important;
}

.map--popup-info .long p {
    width: 175px;
}
.map--popup-info .long p:first-child {
    width: 75px;
}

.map--popup-info .tall p {
    width: calc(75px + 175px);
    min-height: 25px;
}

.leaflet-popup-content .map--popup-body p {
    font-size: 14px;
    margin: 0;
    padding: 0;
}

.leaflet-popup-content button svg {
    width: 22px;
    height: 22px;
    margin: 0 0 0 5px;
}

.leaflet-popup-content button {
    font-size: 14px !important;
    padding: 0 !important;
}

.map--popup-list a {
    width: fit-content !important;
    margin-right: 10px !important;
    color: var(--black) !important;
    font-weight: 500;
    font-size: 14px;
}

.map--popup-list a:hover {
    text-decoration: none;
}
/*4. MAP POPUPS ENDS*/

/*5. MAP ADD STARTS*/
.map--add {
    overflow: hidden;
    width: 100vw;
    height: 80vh;
    position: absolute;
    z-index: 1;
    border-radius: 20px 20px 0 0;
    background-color: var(--white);
}

/*5.1 ADD NAV*/
.map--add-nav {
    background-color: var(--obos-green);
    color: var(--white);
    width: 25vw;
    height: 100%;
    padding: 40px 0;
    box-sizing: border-box;
}

.map--add-nav div {
    width: 100%;
    height: 100%;
    padding: 25px 0;
}

.map--add-nav h3 {
    width: 80%;
    height: 40px;
    display: flex;
    align-items: center;
}

.map--add-nav-buttons {
    box-sizing: border-box;
    margin: 25px 0 75px 0;
    width: 80% !important;
}

.map--add-nav-buttons button {
    min-width: 150px;
    width: 100%;
}
/*5.2 ADD FORMS*/
.map--no-add-form, .map--add-form {
    padding: 40px 50px 20px 50px;
    box-sizing: border-box;
    height: 80vh;
    width: 75vw;
}

.map--add-form-header {
    box-sizing: border-box;
    height: 40px;
    margin: 0 !important;
}

.map--add-form-header h3, .map--no-add-form h3 {
    font-weight: 700;
    display: flex;
    align-items: center;
    height: 40px;
}

.map--add-form-header button {
    box-sizing: border-box;
    height: 40px;
    width: 180px;
    display: flex;
    justify-content: space-between;
}

.map--add-form-header button svg {

}

.map--add-form-message {
    min-height: 50px !important;
    width: 100%;
}

.map--add-form-message .info-message {
    height: 50px;
}

.map--add-form form {
    padding: 20px 0 40px 0;
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
}

.map--add-form-inputs {
    width: 100%;
    height: 100%;
    align-items: flex-end;
}

.map--add-form-input-row {
    width: 100%;
}

.map--add-form-input-container {
    width: 48%;
}

.map--add-form-input-container .checkbox {
    box-sizing: border-box;
    min-height: 50px;
    max-height: 50px;
}

.map--add-form-input-container.message {
    justify-content: flex-end;
    height: 100%;
}

.map--add-form-input-container.message .info-message {
    height: 50px;
}

.map--add-form-input-container.message .info-message p {
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    width: 80%;
}

.whole-row {
    width: 100%;
}

.map--add-form label {
    margin-top: 10px;
    width: 100%;
}

.map--add-form input, .map--add-form select {
    margin: 0 !important;
    width: 100%;
}

.map--add-form form button {
    bottom: 0;
    width: 100px;
    margin: 0 5px;
}

.map--add-form .checkbox {
    height: 55px;
}

.map--add-form input[type="checkbox"] {
    width: 22px;
    height: 22px;
    border: 3px solid var(--light-grey);
}

.map--add-ports-display {
    width: calc(100% - 491px);
    box-sizing: border-box;
    height: 50px;
    border-radius: 20px;
    border: 3px solid var(--white);
    padding-left: 10px;
    background-color: var(--white);
    margin-bottom: 20px;
    resize: none;
}

.map--add-ports-select {
    margin-bottom: 20px;
}

.map--add-ports-buttons {
    margin-bottom: 20px;
    height: 50px;
}

.map--add-ports-buttons button, .map--add-ports-select button {
    width: 125px !important;
    height: 50px;
}

.map--add-ports-select button {
    margin: 0 0 0 10px !important;
}

.map--add-ports-buttons button {
    box-sizing: border-box;
    margin: 0 0 0 10px !important;
}

/*5. MAP ADD ENDS*/

/*6. MAP UPDATE BUTTONS STARTS*/
.map--update-coordinate--buttons {
    padding: 10px;
    position: absolute;
    top: calc(100vh - 10vh);
    left: calc(25vw + 50px);
    z-index: 1000;
    pointer-events: auto;
}

.map--update-coordinate--buttons button {
    margin-right: 10px;
}
/*MAP UPDATE BUTTONS ENDS*/
/*------------------------------------------------------------------------------------MAP PAGE ENDS*/
/*------------------------------------------------------------------------------------LIST PAGE STARTS*/
/*CONTAINS*/
/*1. LIST PAGE GENERAL*/
/*2. LIST STATISTICS*/
/*3. LIST CLOSEABLE*/
/*3.1. OPEN STATE*/
/*3.2. HEADER*/
/*3.3. TABLE */
/*3.3.1. COLUMN WIDTHS*/
/*3.3.2. BUTTONS*/

/*1. LIST PAGE GENERAL STARTS*/
.list {
    display: flex;
    overflow: hidden;
}

.list--map {
    position: absolute;
    width: 25vw;
    z-index: 0;
}

.list--content-container {
    margin-left: 22vw;
    overflow: hidden;
    width: 78vw;
    height: 90vh;
    position: absolute;
    z-index: 1;
    background-color: var(--white);
    border-radius: 20px 0 0 20px;
}

.list--content {
    padding: 25px 50px;
    overflow-y: scroll;
    height: calc(100% - 50px);
}

.list--header button svg {
    width: 24px;
}

/*LIST PAGE GENERAL ENDS*/

/*2. LIST STATISTICS STARTS*/
.list--statistics {
    padding: 10px 20px;
    margin-top: 20px;
}

.list--statistics p {
    font-size: 14px;
}

.list--statistics b {
    color: var(--obos-blue);
}
/*2. LIST STATISTICS ENDS*/

/*3. LIST CLOSEABLE STARTS*/
.list--closeable {
    padding: 20px;
    margin-top: 20px;
    overflow: hidden;
}

/*3.1. OPEN STATE */

.list--closeable.open {
    overflow: scroll;
}

.list--closeable.open .list--closeable-container {
    height: fit-content;
    max-height: 500px;
    transition: max-height ease-in-out 1.0s;
    margin-bottom: 10px;
}

.list--closeable.open .list--closeable-header {
    margin-bottom: 15px;
}


.list--closeable.open .list--closeable-header svg:not(h3 svg) {
    transform: rotate(180deg);
    transition: transform 0.5s ease;
}

.list--closeable.open::-webkit-scrollbar {
    display: none;
}

/*3.2. HEADER*/
.list--closeable-header {
    height: fit-content;
    transition: margin-bottom ease-in-out 0.5s;
    cursor: pointer;
}

.list--closeable-header h3 svg,
.list--closeable-header svg {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.list--closeable-header svg:not(h3 svg) {
    transition: transform 0.5s ease;
    rotate: 180deg;
}

/*3.3. TABLE*/
.list--closeable-container {
    overflow: hidden;
    max-height: 0;
    transition: max-height ease-in-out 1.0s;
    width: 100%;
    margin: 0 !important;
}

.list--closeable-container div {
    width: 100%;
}

.list--closeable-table-header {
    width: 100%;
}

.list--closeable-table-data {
    overflow-y: scroll;
    width: 100%;
}

.list--closeable-table-data-row {
    min-width: calc(100% - 225px) !important;
}

.list--closeable-table-data-row.wide, .list--closeable-table-header.wide {
    min-width: 100% !important;
    display: flex;
    justify-content: space-between;
}

.list--closeable-table-data-row p, .list--closeable-table-header p {
    width: 75px;
    min-height: 50px;
    max-height: 100px;
    display: flex;
    align-items: center;
    text-align: left;
}

/*3.3.1. COLUMN WIDTHS*/

.list .w-200 {
    width: 200px;
}

.list .w-150 {
    width: 150px;
}

.list .w-125 {
    width: 125px;
}

.list .w-100 {
    width: 100px;
}

.list .w-75 {
    width: 75px;
}

.list .w-60 {
    width: 60px;
}

/*3.3.2 BUTTONS*/

.list--closeable-table-buttons {
    min-width: 225px;
    max-width: 225px;
}

.list--closeable-table-buttons button {
    box-sizing: border-box;
    height: 30px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    margin: 0 0 0 0;
}

.list--closeable-table-buttons button:last-child {
    margin: 0 10px 0 0;
}
/*3. LIST CLOSEABLE ENDS*/
/*------------------------------------------------------------------------------------LIST PAGE ENDS*/
/*------------------------------------------------------------------------------------INFO PAGE STARTS*/
/*CONTAINS*/
/*1. INFO PAGE GENERAL*/
/*2. INFO PAGE CONTENT*/
/*2.1. HEADER*/
/*2.2. CONTENT DATA*/
/*2.2.1. COMMON*/
/*2.2.2. NAME*/
/*2.2.3. LOG*/
/*2.2.4. DATA*/
/*2.2.5. FILES*/
/*2.2.5.1 SVG SIZES*/
/*2.2.5.2. FULL SCREEN MODE*/
/*2.2.6. CONNECTIONS*/
/*2.2.6.1. SWITCHES*/
/*2.2.6.1.1. ACTIVE*/
/*2.3. CONTENT EDIT*/

/*1. INFO PAGE GENERAL STARTS*/
.info-page {
    box-sizing: border-box;
    height: 90vh;
    width: 100%;
}

.info-page--map {
    position: absolute;
    z-index: 0;
}

.info-page--content {
    box-sizing: border-box;
    width: 78vw;
    padding: 50px 50px 0 50px;
    margin-left: 22vw;
    height: 90vh;
    position: relative;
    z-index: 1;
    background-color: var(--white);
    border-radius: 20px 0 0 20px;
}
/*1. INFO PAGE GENERAL ENDS*/

/*2. INFO PAGE CONTENT STARTS*/
.info-page--content-header,
.info-page--log,
.info-page--data,
.info-page--connections,
.info-page--connections-nodes,
.info-page--switches {
    margin-bottom: 20px;
}

/*2.1 HEADER*/
.info-page--content-header {
    width: 100%;
    padding: 0 10px;
    height: fit-content;
}
.info-page--content-header-buttons .btn-primary-white-bg {
    width: 200px;
}

.info-page--content-header-buttons .btn-secondary-white-bg {
    width: 150px;
}
.info-page--content-header .icon--lock-open-white,
.info-page--content-header .icon--lock-closed-blue {
    width: 16px;
    height: 16px;
}
.info-page--content-header .subscribe-container {
    width: 100%;
    display: flex;
    justify-content: right;
}

.info-page--content-header .subscribe {
    margin-top: 15px;
    width: fit-content;
}

.info-page--content-header .subscribe h4 {
    font-weight: bold;
    margin-right: 10px;
}

.info-page--content-header .subscribe.disabled h4 {
    color: var(--disabled-grey)
}

.info-page--content-header .subscribe.disabled .info-text {
    display: none;
}

.info-page--content-header .subscribe.disabled:hover .info-text {
    display: inline-block;
    position: absolute;
    background-color: var(--white);
    height: fit-content;
    font-size: 16px;
    border: 3px solid var(--obos-blue);
    border-radius: 20px;
    width: 200px;
    top: 105px;
    padding: 10px;
    right: 180px;
    color: var(--obos-blue);
    text-align: center;
}

.info-page--content-header .subscribe.disabled .switch input, .info-page--content-header .subscribe.disabled .switch span {
    cursor: not-allowed !important;
}

.info-page--content-header .subscribe.disabled .switch input:checked + .slider {
    background-color: var(--disabled-green);
}


/*2.2. CONTENT DATA*/
.info-page--content-data {
    overflow: scroll;
    padding: 0 10px;
    height: 100%;
    width: 100%;
}

.info-page--content-data .info-message {
    margin-bottom: 10px;
}

/*2.2.1. COMMON*/
.info-page--log, .info-page--data, .info-page--files, .info-page--connections, .info-page--switches, .info-page--connections-nodes {
    box-sizing: border-box;
    width: 100%;
    border-radius: 20px;
}

.info-page--log-headers, .info-page--data-headers, .info-page--files, .info-page--connections-headers, .info-page--switches-headers, .info-page--connections-nodes-headers {
    background-color: var(--light-blue);
}

.info-page--log-headers p, .info-page--data-headers p, .info-page--files p,
.info-page--connections .count,
.info-page--connections-nodes-headers p,
.info-page--switches-headers p {
    font-weight: 700;
}

.info-page--log p, .info-page--data p, .info-page--connections p, .info-page--connections-nodes p, .info-page--switches-headers p, .info-page--switches-headers {
    height: 70px !important;
    display: flex;
    align-items: center;
}

/*2.2.2. NAME*/
.info-page--name svg, .info-page--name-input svg {
    width: 70px;
    height: 70px;
}

.info-page--name svg {
    margin-right: 33px;
    border-radius: 50px;
}

.info-page--name-input svg {
    margin-right: 20px;
    border-radius: 50px;
}

.info-page--name .icon--kum-wall, .info-page--name-input .icon--kum-wall {
    border-radius: 10px;
}

.info-page--name, .info-page--name-input input {
    box-sizing: border-box;
    height: 70px;
    margin: 0 !important;
}

.info-page--name-input input {
    font-size: 24px;
    height: 50px;
    font-weight: 700;
    width: 400px;
}

/*2.2.3. LOG*/
.info-page--log {
    height: 140px;
}

.info-page--log-headers {
    border-radius: 20px 20px 0 0 ;
}

.info-page--log-data, .info-page--log-headers {
    width: 100%;
}

.info-page--log-data p, .info-page--log-headers p {
    justify-content: center;
    width: 100%;
}

/*2.2.4. DATA*/
.info-page--data-headers {
    box-sizing: border-box;
    border-radius: 20px 0 0 20px;
    padding-left: 20px;
}

.info-page--data-headers p {
    box-sizing: border-box;
    width: 150px;
}

.info-page--data-data {
    box-sizing: border-box;
    padding-left: 20px;
}

.info-page--data-data p {
    box-sizing: border-box;
    width: 310px;
}

/*2.2.5. FILES*/
.info-page--files {
    width: 500px;
    height: 420px;
}

.info-page--files.noImages {
    height: 100px;
    justify-content: center;
}

.info-page--files label {
    margin: 0 !important;
}

.info-page--files img {
    width: 300px;
    height: 250px;
    margin-bottom: 10px;
    border-radius: 20px;
}

.info-page--files img, .info-page--files-image-buttons, .info-page--files-pdf-buttons {
    width: 300px;
}

.info-page--files-image {
    padding-top: 10px;
}

.info-page--files-pdf {
    padding-bottom: 10px;
}

.info-page--files-image-buttons,
.info-page--files-pdf-buttons {
    background-color: var(--white);
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 20px;
}

.info-page--files-pdf svg:not(.chevron), .info-page--files-image svg:not(.chevron) {
    margin: 0 5px;
}

input[type="file"], .info-page--files-fullscreen-container input[type="file"], .info-page--files svg:not(.chevron) {
    display: none;
}

.info-page--files-pdf:hover svg, .info-page--files-image:hover svg {
    display: block;
}

/*2.2.5.1 SVG SIZES*/
.info-page--files-image-buttons label,
.info-page--files-pdf-buttons label,
.info-page--files-fullscreen-buttons label {
    margin: 0 !important;
}

.info-page--files .chevron,
.info-page--files-fullscreen-buttons .icon--blue-chevron-r,
.info-page--files-fullscreen-buttons .icon--blue-chevron-l {
    width: 30px;
    height: 30px;
}

.info-page--files .icon--plus-blue, .info-page--files-fullscreen-buttons .icon--plus-blue {
    width: 18px;
    height: 18px;
}

.info-page--files .icon--blue-expand,
.info-page--files-fullscreen-buttons .icon--blue-shrink,
.info-page--files-fullscreen-buttons .icon--blue-download,
.info-page--files .icon--blue-download
{
    width: 20px;
    height: 20px;
}

.icon--trash-blue {
    width: 28px;
    height: 28px;
}

/*2.2.5.1.1. SVG HOVER*/
/*Darker blue*/
.info-page--files .blue-svg:hover,
.info-page--files-fullscreen-buttons .icon--blue-chevron-r:hover,
.info-page--files-fullscreen-buttons .icon--blue-chevron-l:hover,
.info-page--files-fullscreen-buttons .icon--blue-shrink:hover,
.info-page--files-fullscreen-buttons .icon--blue-download:hover {
    filter: brightness(60%);
    cursor: pointer;
}

/*Green*/
.info-page--files .icon--plus-blue:hover, .icon--plus-blue:hover{
    filter: invert(63%) sepia(100%) saturate(543%) hue-rotate(115deg) brightness(50%) contrast(91%);
    cursor: pointer;
}

/*Red*/
.info-page--files .icon--trash-blue:hover,
.info-page--files-fullscreen-buttons .icon--trash-blue:hover {
    filter: saturate(100%) invert(21%) sepia(98%) saturate(5635%) hue-rotate(347deg) brightness(60%) contrast(118%);
    cursor: pointer;
}

/*Grey-Blue ish*/
.info-page--files svg.disabled,
.info-page--files-fullscreen-buttons .icon--plus-blue.disabled,
.info-page--files-fullscreen-buttons .icon--blue-chevron-l.disabled,
.info-page--files-fullscreen-buttons .icon--blue-chevron-r.disabled,
.info-page--files-fullscreen-buttons .icon--trash-blue.disabled
{
    filter: saturate(60%) !important;
    opacity: 50%;
    cursor: not-allowed !important;
}

/*2.2.5.2. FULL SCREEN MODE*/
.info-page--files-fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
}

.info-page--files-fullscreen-buttons {
    background-color: var(--light-blue);
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 20px;
    margin-top: 20px;
    width: 700px;
}
.info-page--files-fullscreen-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.info-page--files-fullscreen-container img {
    border-radius: 20px;
    cursor: zoom-in;
    object-fit: contain;
    max-width: 1024px;
    max-height: 550px;
    transition: transform 0.3s ease;
}

.info-page--files-fullscreen-container img.zoomed-in {
    transform: scale(3);
    transition: transform 0.3s ease;
    cursor: zoom-out;
}

.info-page--files-fullscreen-container .pdf {
    height: 600px;
    width: 670px;
}

.info-page--files-fullscreen-update-buttons svg {
    margin: 0 10px;
}

/*2.2.6. CONNECTIONS*/
.info-page--connections-headers {
    box-sizing: border-box;
    border-radius: 20px 0 0 20px;
    padding-left: 20px;
}

.info-page--connections-headers p {
    box-sizing: border-box;
    width: 150px;
}

.info-page--connections-data {
    box-sizing: border-box;
    padding: 0 40px 0 20px;
    width: 100%;
}

.info-page--connections-data a {
    color: var(--black);
    font-weight: 500;
    height: 70px;
    display: flex;
    align-items: center;
}

.info-page--connections-data a:not(.info-page--connections-data a:first-child) {
    padding-left: 20px;
}

.info-page--connections-data a:hover {
    color: var(--obos-blue);
    text-decoration: none;
}

.info-page--connections-data .count {
    color: var(--obos-blue);
}

.info-page--connections-update-nodes {
    height: 70px;
    margin-left: 10px;
    margin-bottom: 20px;
}

.info-page--connections-update-nodes select {
    box-sizing: border-box;
    width: 200px;
    height: 70px !important;
    border: solid 3px var(--white);
    margin: 0 10px 0 0!important;
}

.info-page--connections-update-nodes button {
    height: 50px;
    border-radius: 25px!important;
    font-size: 16px;
    width: 165px;
}

.info-page--connections .info-page--connections-update-nodes * {
    margin-right: 10px;
}

.info-page--connections-update-nodes .add-node--searchable-dropdown {
    position: relative;
}

.info-page--connections-update-nodes .add-node--searchable-dropdown input {
    box-sizing: border-box;
    height: 70px !important;
    margin-bottom: 0 !important;
    width: 200px !important;
    margin-right: 10px !important;
}

.info-page--connections-update-nodes .add-node--searchable-dropdown .dropdown-list {
    position: absolute;
    box-sizing: border-box;
    bottom: 80px;
    background-color: var(--white);
    border-radius: 20px;
    border: solid 3px var(--obos-blue);
    padding: 10px;
    width: 200px;
    max-height: 200px;
    overflow-y: scroll;
}

.info-page--connections-update-nodes .add-node--searchable-dropdown .dropdown-item {
    color: var(--obos-blue);
    font-weight: bold;
    cursor: pointer;
}

.add-node--searchable-dropdown .dropdown-list .dropdown-item:hover {
    color: var(--obos-green);
}

.add-node--searchable-dropdown .dropdown-list .dropdown-item.disabled {
    cursor: not-allowed;
    color: var(--disabled-blue);
}

/*2.2.6.1 SWITCHES*/
.info-page--switches-headers {
    cursor: pointer;
    height: 30px;
    border-radius: 20px;
    padding: 0 20px;
}

.info-page--switches-content {
    max-height: 0;
    overflow-y: scroll;
    transition: max-height  1.5s ease;
}

.info-page--switches-data {
    padding: 20px 20px 0 20px;
}

.info-page--switches-data div p {
    padding: 10px 20px;
}

.info-page--switches-headers svg {
    width: 30px;
    height: 30px;
    transform: rotate(180deg);
    transition: transform 0.5s ease;
}

.info-page--switches-port-info {
    width: 100%;
}

.info-page--switches-port-info div {
    width: calc(25% - 10px);
}

.info-page--switches-port-info p {
    width: 100%;
    text-align: left;
    padding-left: 0 !important;
}

.info-page--switches-port-info svg {
    width: 10px;
    height: 10px;
    margin: auto 10px auto 10px;
}

/*2.2.6.1.1. ACTIVE*/
.info-page--switches.active .info-page--switches-content {
    height: fit-content;
    max-height: 600px;
}

.info-page--switches.active .info-page--switches-headers {
    border-radius: 20px 20px 0 0;
}

.info-page--switches.active .info-page--switches-headers svg {
    transform: rotate(360deg);
}

.info-page--switches ::-webkit-scrollbar {
    display: none;
}

/*2.3. CONTENT EDIT*/
.info-page--edit-info input,
.info-page--edit-info-row-input,
.info-page--edit-info select {
    width: 45%;
}

.info-page--edit-info .wide-input, .info-page--edit-info-row input, .info-page--edit-info-row select {
    width: 100%;
}

.info-page--edit-info-row {
    width: 100%;
}
/*2. INFO PAGE CONTENT ENDS*/
/*------------------------------------------------------------------------------------INFO PAGE ENDS*/
/*------------------------------------------------------------------------------------PROFILE PAGE STARTS*/
/*CONTAINS*/
/*1. PROFILE GENERAL*/
/*2. PROFILE DISPLAYS*/
/*2.1. DASHBOARD*/
/*2.1.1. COMMON*/
/*2.1.2. UPDATES*/
/*2.1.3. NOTIFICATIONS*/
/*2.1.3.1 SVG*/
/*2.1.3.2 OPEN CLOSED STATE*/
/*2.1.3.3 BIG SCREEN*/
/*2.2. CHANGE USER*/
/*2.3. SEND FEEDBACK*/
/*2.4. ADMIN*/
/*2.4.1. DISPLAYS*/
/*2.4.2. ALL USER*/
/*2.4.3. CREATE USER & EDIT USER*/
/*2.5. DEVELOPER*/
/*2.5.1. SEE FEEDBACK*/
/*2.5.2. POST UPDATE*/
/*2.6. SUBSCRIPTION*/

/*1. PROFILE GENERAL STARTS*/
.profile {
    position: relative;
    height: 100vh;
}

.profile--map-wrapper {
    position: relative;
    width: 100vw;
    height: 150px !important;
}

.profile--map {
    box-sizing: border-box;
    border-radius: 0 0 20px 20px;
    z-index: 3;
    width: 100vw;
    height: 150px;
}

.snow-overlay {
    position: absolute;
    top: 90px !important;
    left: 0;
    width: 100vw;
    height: 150px !important;
    z-index: 3;
    border-radius: 0 0 20px 20px;
    background: url('../gifs/snowinggif2.gif') repeat;
}


.profile--user {
    position: absolute;
    width: 200px;
    top: calc(10vh + 50px);
    left: 25px;
    z-index: 3;
    border-radius: 50%;
    margin-left: 25px;
    margin-bottom: 20px;
}

.profile--user img {
    border-radius: 50%;
}

.profile--user h2 {
    width: 200px;
    height: 80px;
    color: var(--white);
}

.profile--body {
    height: calc(100vh - 90px);
    width: 100%;
    position: absolute;
    bottom: 0;
}

.profile--nav {
    box-sizing: border-box;
    z-index: 2;
    height: 100%;
    width: 300px;
    position: relative;
    background-color: var(--obos-blue);
    border-radius: 0 0 20px 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.profile--nav-buttons {
    height: calc(100% - 150px - 100px);
}

.profile--nav button {
    width: 200px;
    margin: 15px 0;
    font-size: 18px;
    border: 3px solid var(--white);
}

.profile--nav button.active {
    width: 200px;
    margin: 15px 0;
    font-size: 18px;
    border: 3px solid var(--white);
}

.profile--nav button.active:hover {
    border: 3px solid var(--white);
}

/*1. PROFILE GENERAL ENDS*/
/*2. PROFILE DISPLAYS STARTS*/

.profile--display {
    box-sizing: border-box;
    height: calc(100% - 150px);
    margin-top: 150px;
    background-color: var(--white);
    width: calc(100vw - 300px);
}

.profile--display-page, .profile--display-page-dashboard {
    width: 100%;
    height: 100%;
    color: var(--obos-blue);
    box-sizing: border-box;
    overflow-y: hidden;
    margin-top: 0!important;
    padding-left: 50px;
}

.profile--display-page {
    padding-right: 50px;
}

.profile--display-header {
    width: 100%;
    height: 40px !important;
    margin-top: calc(25px + 10px);
}

.profile--display-header-no-h2 {
    margin-bottom: 15px;
    height: 80px;
    margin-top: 15px;
}

/*2. PROFILE DISPLAYS STARTS*/
.profile--display-header-notifications {
    width: 100%;
    height: 60px;
}

.profile--display-header h2, .profile--display-header-notifications h2 {
    margin: 0 !important;
    width: 300px !important;
}

.profile--display-header-message {
    width: calc(100% - 300px);
    margin-left: 10px;
    box-sizing: border-box;
    margin-bottom: 0!important;
}

.profile--display-header-no-h2 .profile--display-header-message {
    width: 100%;
    box-sizing: border-box;
    margin-left: 0 !important;
}

.profile--display-header-message .info-message {
    box-sizing: border-box;
    height: 60px;
    width: 100%;
}

.profile--display-page .description {
    margin-top: 20px;
    color: var(--black)
}

.profile--display-body {
    margin-top: 30px;
    width: 100%;
    padding: 0 !important;
}

.profile--display-body.higher {
    margin-top: 15px;
}

.profile--display-body.higher {
    box-sizing: border-box;
    width: 100%;
    height: 475px;
    overflow-y: auto;
    padding: 15px;
}

.profile--admin-new-user, .profile--developer-add-update, .profile--admin-edit-user, .profile--display-header-message {
    box-sizing: border-box;
    padding: 10px;
}

.profile--admin-nav, .profile--developer-nav, .profile--feedback-nav {
    height: 40px !important;
}

.profile--admin-nav button, .profile--developer-nav button {
    height: 40px;
    width: 200px;
}

.profile--feedback-nav button {
    height: 40px;
    width: 275px;
}

/*2.1. DASHBOARD*/
/*2.1.1. COMMON*/
.profile--dashboard--notifications h2,
.profile--dashboard--notifications-big-screen h2,
.profile--dashboard--updates h2 {
    height: 40px;
    margin-bottom: 15px;
    text-align: left;
    width: fit-content;
}

.profile--dashboard--all-notifications,
.profile--dashboard--all-updates {
    padding-top: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    display: block;
    height: calc(100% - 40px) !important;
}

.profile--dashboard--updates .profile--dashboard-update,
.profile--dashboard--notifications .profile--dashboard-notification,
.profile--dashboard--notifications-big-screen .profile--dashboard-notification {
    box-sizing: border-box;
}

/*2.1.2. UPDATES*/
.profile--dashboard--updates {
    height: 100%;
    width: 1000px;
    margin-right: 20px;
    transition: max-width 0.5s ease;
    margin-top: 0 !important;
}

.profile--dashboard--updates ::-webkit-scrollbar {
    display: none;
}

.profile--dashboard--all-updates {
    transition: max-width 0.5s ease;
    width: 100%;
    overflow-y: scroll;
}

.profile--dashboard-update {
    padding: 10px;
    margin: 0 20px 20px 20px;
    transition: max-width 0.5s ease;
}

.profile--dashboard-update button {
    margin-top: 10px;
    box-shadow: unset;
}

.profile--dashboard--updates.slim {
    max-width: calc(100% - 600px);
}

.profile--dashboard--updates.slim .profile--dashboard-update {
    max-width: 90%;
}

.profile--dashboard--updates.wide-input {
    max-width: calc(100% - 40px);
}

.profile--dashboard--updates.wide-input .profile--dashboard-update {
    max-width: 900px;
}

.profile--dashboard--updates .profile--dashboard-update p:nth-child(3) {
    color: var(--black);
}

.profile--dashboard--updates .profile--dashboard-update p:nth-child(4) {
    color: var(--obos-green);
    filter: brightness(80%);
}

/*2.1.3. NOTIFICATIONS*/
.profile--dashboard--notifications,
.profile--dashboard--notifications-big-screen {
    padding-top: 10px;
    box-sizing: border-box;
    background-color: var(--light-blue);
    height: calc(100% - 50px);
    border-radius: 20px 0 0 20px;
}

.profile--dashboard--notifications-big-screen {
    padding-left: 40px;
}

.profile--dashboard--all-notifications {
    height: calc(100% - 10px);

}

.profile--dashboard-notification {
    border-radius: 20px;
    width: 495px;
    background-color: var(--white);
    padding: 10px;
    margin: 0 10px 20px 10px;
}

.profile--dashboard-notification p:first-child,
.profile--dashboard-notification .date {
    font-weight: bold;
}

.profile--dashboard-notification .triggeruser {
    color: var(--obos-blue);
    border-radius: 20px 0 0 20px;
    width: 120px;
}

.profile--dashboard-notification .triggeruser.you {
    color: var(--obos-green);
}

.profile--dashboard-notification .message {
    width: 240px !important;
    height: 60px;
    color: var(--black);
}

.profile--dashboard-notification .date {
    width: 100px;
    font-size: 12px;
    color: var(--obos-green);
    filter: brightness(80%);
    text-align: center;
}

/*2.1.3.1 SVG*/
.profile--dashboard--notifications svg {
    margin: 10px;
    height: 40px !important;
    width: 40px !important;
    transform: rotate(90deg);
    transition: transform 0.5s ease;
    cursor: pointer;
}

.profile--dashboard--notifications svg:hover {
    filter: brightness(70%);
}

.profile--dashboard--notifications.open svg {
    transform: rotate(270deg);
    transition: transform 0.5s ease;
}

/*2.1.3.2 OPEN CLOSED STATE*/
.profile--dashboard--notifications .dashboard--notifications-container {
    max-width: 0;
    overflow: hidden;
    transition: max-width 0.5s ease;
}

.profile--dashboard--notifications.open {
    box-sizing: border-box;
    max-width: 600px;
}

.profile--dashboard--notifications.open .profile--dashboard--all-notifications {
    width: 540px;
    box-sizing: border-box;
    transition: max-width 0.5s ease;
}

.profile--dashboard--notifications.open .dashboard--notifications-container {
    width: 580px;
    max-width: 580px;
    padding-right: 20px;
    transition: max-width 0.5s ease;
    box-sizing: border-box;
}

.profile--dashboard--notifications.open .dashboard--notifications-container ::-webkit-scrollbar {
    display: block;
}

.profile--dashboard--notifications .dashboard--notifications-container ::-webkit-scrollbar {
    display: none;
}

/*2.1.3.3. BIG SCREEN*/
.profile--dashboard--notifications-big-screen {
    overflow-y: hidden;
}
.profile--dashboard--notifications-big-screen .dashboard--notifications-container {
    width: 1400px;
    max-width: 1400px;
    padding-right: 20px;
    transition: max-width 0.5s ease;
    box-sizing: border-box;
    height: 100% !important;
    overflow-y: hidden;
}

.profile--dashboard--notifications-big-screen .dashboard--notifications-columns {
    overflow-y: scroll;
    height: 100% !important;
    width: 100%;
}

.profile--dashboard--notifications-big-screen .profile--dashboard--all-notifications {
    padding-top: 0 !important;
    min-width: 400px;
    max-width: 800px;
}

.profile--dashboard--notifications-big-screen .profile--dashboard--all-notifications:first-child {
    margin-right: 10px;
}

.profile--dashboard--notifications-big-screen .profile--dashboard-notification {
    width: 375px;
}

.profile--dashboard--notifications-big-screen .profile--dashboard-notification .message {
    width: 150px;
}

.profile--dashboard--notifications-big-screen .profile--dashboard-notification .date {
    width: 80px;
}

/*2.2. CHANGE USER*/
.profile--edit-user-form {
    width: calc(50% - 10px);
}

.profile--edit-user-form input {
    width: calc(100%);
}

.profile--display-page .sms-code--form {
    margin-top: 20px;
}

/*2.3. FEEDBACK*/
/*2.3.1. SEND FEEDBACK*/
.profile--send-feedback-form select {
    width: calc(50% - 10px);
}

.profile--send-feedback-form textarea {
    width: 100%;
    height: 150px;
}

.profile--send-feedback-form {
    margin-bottom: 10px;
}

/*2.4. ADMIN*/
/*2.4.1. DISPLAYS*/
.profile--admin-all-users, .profile--developer-see-feedback {
    height: 450px;
    overflow-y: scroll;
    padding: 5px;
    margin-top: 60px;
}

.profile--admin-all-users .user p, .feedback--header p, .feedback--data p, .profile--admin-handle-data-closeable-container {
    color: var(--black);
}

.profile--admin-all-users .user p, .feedback--header p, .profile--admin-handle-data-closeable-header h4 {
    font-weight: 700;
}

/*2.4.2.2. ALL USER*/
.profile--admin-all-users .user, .profile--admin-all-users button {
    margin-bottom: 20px;
    height: 60px;
}

.profile--admin-all-users .user {
    box-sizing: border-box;
    width: calc(100% - 115px);
    color: var(--black);
    font-weight: 700;
    padding: 0 20px;
    margin-left: 5px;
}

.profile--admin-all-users button {
    width: 100px;
}

/*2.4.2.3. CREATE USER & EDIT USER*/
.profile--admin-new-user div div, .profile--admin-edit-user div div {
    width: calc(50% - 10px);
}

.profile--admin-new-user input, .profile--admin-new-user select,
.profile--admin-edit-user input, .profile--admin-new-user select {
    width: calc(100%);
}

.profile--admin-new-user .input-disabled, .profile--admin-edit-user .input-disabled {
    cursor: not-allowed;
}

.profile--admin-edit-user input[type="checkbox"] {
    width: 34px;
    height: 34px;
    margin: 8px 0;
    border-radius: 10px;
}

.profile--admin-edit-user p {
    color: var(--black);
    margin-left: 10px;
}

/*2.4.2.4. HANDLE DATA*/
.profile--admin-handle-data div {
    width: calc(50% - 10px);
}

.profile--admin-handle-data form, .profile--admin-handle-data-containers {
    padding: 10px 5px;
}

.profile--admin-handle-data form input, .profile--admin-handle-data form select {
    width: calc(100%);
}

.profile--admin-handle-data form div {
    width: 100%;
}

.profile--admin-handle-data-note {
    margin-top: 20px;
    padding: 10px;
    box-sizing: border-box;
    width: calc(100% - 10px);
    border-radius: 25px;
    border: 3px solid var(--obos-green);
    font-size: 14px;
    text-align: center;
    font-weight: 700;
}

.profile--admin-handle-data-containers {
    box-sizing: border-box;
    max-height: 50vh;
    overflow-y: scroll;
    width: 100% !important;
}

.profile--admin-handle-data-closeable-header {
    width: 100% !important;
    cursor: pointer;
}

.profile--admin-handle-data-closeable-header h4 {
    height: 40px !important;
}

.profile--admin-handle-data-closeable-header svg {
    width: 30px;
    height: 30px;
    transform: rotate(180deg);
    transition: transform 0.5s ease;
}

.profile--admin-handle-data-closeable-container {
    width: 100% !important;
    border-radius: 25px;
    box-sizing: border-box;
    padding: 10px;
    height: auto;
    transition: height 0.5s ease;
}

.profile--admin-handle-data-closeable {
    max-height: 0;
    overflow-y: hidden;
    transition: max-height 0.5s ease;
    width: 100% !important;
}

.profile--admin-handle-data-closeable div {
    width: 100% !important;
    height: 40px !important;
}

.profile--admin-handle-data-closeable div button {
    height: 30px;
    margin-right: 5px;
}

.profile--admin-handle-data-closeable-container:not(:first-of-type) {
    margin-top: 10px;
}

/* Open state */

.profile--admin-handle-data-closeable-container.open .profile--admin-handle-data-closeable {
    max-height: 200px;
    overflow-y: scroll;
    transition: max-height 0.5s ease;
    width: 100% !important;
}

.profile--admin-handle-data-closeable-container.open svg {
    transform: rotate(0deg);
    transition: transform 0.5s ease;
}

/*2.5. DEVELOPER*/
/*2.5.1. SEE FEEDBACK*/

/*2.5.2. POST UPDATE*/
.profile--developer-add-update select, .profile--developer-add-update input {
    width: calc(50%);
}

.profile--developer-add-update textarea {
    width: calc(100%);
    height: 100px;
}

/*2.6. SUBSCRIPTIONS*/
.profile--subcriptions-buttons {
    width: 100%;
    margin-bottom: 30px;
}

.profile--subcriptions-buttons svg {
    height: 40px;
    width: 40px;
    cursor: pointer;
    box-sizing: border-box;
}

.profile--subcriptions-buttons svg:hover {
    border: 3px solid var(--obos-green);
}

.profile--subcriptions-buttons svg.active {
    border: 3px solid var(--obos-blue);
}

.profile--subcriptions-buttons svg:not(.icon--kum-wall) {
    border-radius: 100%;
}

.profile--subcriptions-buttons .icon--kum-wall {
    border-radius: 17%;
}

.profile--subscription, .subscription-none {
    position: relative;
    background-color: var(--white);
    border-radius: 20px;
    box-sizing: border-box;
    height: 50px;
    margin-bottom: 10px;
    padding: 0 10px;
    color: var(--black);
    font-weight: bold;
}

.profile--subscription p {
    color: var(--black);
    font-weight: bold;
    width: 200px;
}

.profile--subscription .subscription-name {
    cursor: pointer;
}

.profile--subscription .subscription-name, .profile--subscription svg {
    margin-right: 15px;
}

.profile--subscription svg {
    height: 30px;
    width: 30px;
}

.profile--subscription div {
    width: 400px;
    text-align: left;
}

.profile--subscription .subscription-name:hover {
    color: var(--obos-blue) !important;
}

.profile--subscription-help {
    display: none;
    position: absolute;
    background-color: var(--white);
    color: var(--obos-blue);
    font-weight: unset;
    padding: 10px;
    border: 3px solid var(--obos-blue);
    border-radius: 20px;
    width: 200px;
    height: auto;
    right: 0;
    bottom: 50px;
}

.profile--subscription .switch:hover + .profile--subscription-help {
    display: block;
}

/*2. PROFILE DISPLAYS ENDS*/
/*------------------------------------------------------------------------------------PROFILE PAGE ENDS*/
/*------------------------------------------------------------------------------------CHAT PAGE STARTS*/
/*CONTAINS*/
/*1. CHAT GENERAL*/
/*2. CHAT ALL SESSIONS*/
/*2.1. SEARCH*/
/*2.2. TOGGLE UNREAD*/
/*2.3. SESSIONS*/
/*3. CHAT ALL MESSAGES */
/*4. CHAT OPEN CHAT*/
/*5. SEND MESSAGE*/

/*1. CHAT GENERAL*/
.chat {
    background-color: var(--light-blue);
    height: 100vh;
}

.chat--no-open-chat {
    color: var(--obos-blue);
    background-color: white;
    height: 120px;
    padding: 0 20px;
    border-radius: 20px;
}

/*2. CHAT ALL SESSIONS*/
.chat--all {
    background-color: var(--obos-blue);
    color: white;
    height: calc(100vh - 90px);
    width: 25vw;
    border-radius: 0 20px 20px 0;
    padding: 30px;
    box-sizing: border-box;
}

.chat--users-search, .chat--unread-switch {
    margin-bottom: 20px;
}

/*2.1. SEARCH*/
.chat--users-search {
    background-color: var(--white);
    border-radius: 20px;
    height: 50px;
    width: 300px;
    box-sizing: border-box;
}

.chat--users-search input {
    box-shadow: none;
    height: 50px;
    margin: 0 0 0 10px;
    padding: 0;
}

.chat--users-search input:focus {
    border: solid 3px var(--white) !important;
}

.chat--users-search svg {
    height: 36px;
    width: 36px;
    margin-right: 10px;
}
/*2.2. TOGGLE UNREAD*/
.chat--unread-switch {
    width: 300px;
}

.chat--unread-switch p {
    height: 21px;
}
.chat--unread-switch .switch {
    margin-right: 10px;
    height: 21px;
    min-width: 40px;
    max-width: 40px;
    margin-bottom: 0 !important;
    border-radius: 30px;
}

.chat--unread-switch .slider:before {
    height: 14px;
    width: 14px;
}
/*2.3. SESSIONS*/
.chat--all-sessions {
    height: 550px;
    overflow-y: scroll;
    width: 300px;
}

.chat--session {
    box-sizing: border-box;
    height: 80px;
    padding: 10px;
    border: 3px solid var(--obos-blue);
    border-radius: 20px;
    margin: 7px 0;
    cursor: pointer;
    width: 100%;
}

.chat--session-info {
    width: calc(100% - 75px);
}

.chat--session h4 {
    font-size: 16px;
}

.chat--session p {
    color: var(--light-blue) !important;
    font-size: 14px;
}

.chat--session:hover {
    border: 3px solid var(--white);
}

.chat--session.chosen {
    background-color: var(--dark-blue);
    border: 3px solid var(--white);
}

.chat--session .chat--session-icons .icon--new-message {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.chat--session .chat--session-icons .chat--hour-since {
    width: 45px;
    height: 20px;
    margin-left: 5px;
    background-color: var(--dark-blue);
    color: var(--obos-blue) !important;
    font-weight: bold;
    border-radius: 20px;
}

.chat--session.chosen .chat--session-icons .chat--hour-since {
    background-color: var(--obos-blue);
    color: var(--dark-blue) !important;
}

.chat--no-matches {
    box-sizing: border-box;
    width: 100%;
    background-color: var(--dark-blue);
    height: 80px;
    border: 3px solid var(--white);
    border-radius: 20px;
    padding: 0 10px;
}

/*3. CHAT ALL MESSAGES */
.chat--messages-container {
    height: 550px;
    overflow-y: scroll;
    width: 716px;
    margin: 0 0 30px 0;
}

.chat--date-label {
    text-align: center;
    color: var(--obos-blue);
    opacity: 75%;
    margin-bottom: 10px;
    font-size: 18px;
}

.chat--message {
    box-sizing: border-box;
    width: 100%;
    padding: 0 8px;
}

.chat--message:not(:last-child) {
    margin-bottom: 10px;
}

.chat--message .message {
    min-height: 20px;
    width: fit-content;
    max-width: 500px;
    padding: 10px 20px;
    border-radius: 20px;
}

.chat--message.you {
    align-items: flex-end;
}

.chat--message.you .message {
    background-color: var(--obos-blue);
    color: white;
}

.chat--message.otherUser .message{
    background-color: var(--white);
    color: var(--black);
}

.chat--message p:nth-child(2) {
    color: var(--obos-blue);
    font-size: 12px;
    font-weight: bold;
    opacity: 75%;
}

.chat--tagged-object {
    color: var(--obos-blue);
    font-weight: bold;
    cursor: pointer;
}

.chat--message.otherUser .chat--tagged-object:hover {
    color: var(--obos-green);
}

.chat--message.you .chat--tagged-object {
    color: var(--white);
}

.chat--message.you .chat--tagged-object:hover {
    color: var(--obos-green);
}

.chat--no-messages {
    background-color: var(--white);
    height: 70px;
    width: 450px;
    border-radius: 20px;
    color: var(--obos-blue);
    font-weight: bold;
}

/*4. CHAT OPEN CHAT*/
.chat--open {
    height: calc(100vh - 90px);
    width: 75vw;
}
/*5. SEND MESSAGE*/
.chat--input-container {
    background-color: white;
    border-radius: 20px;
    height: 70px;
    width: 700px;
}

.chat--input {
    box-shadow: unset;
    width: calc(100% - 100px);
    box-sizing: border-box;
    height: 70px !important;
    padding: 0 10px;
    min-height: 40px;
    margin: 0 !important;
    border: 3px solid var(--white) !important;
}

.chat--input-container input:focus {
    border: unset !important;
    outline: none !important;
    box-shadow: none !important;
}

.chat--input-buttons {
    position: relative;
}

.chat--input-buttons svg {
    height: 30px;
    width: 30px;
    cursor: pointer;
    margin: 0 5px;
}

.chat--help-box {
    visibility: hidden;
    position: absolute;
    background-color: var(--white);
    border-radius: 20px;
    border: 3px solid var(--obos-blue);
    padding: 15px;
    box-sizing: border-box;
    width: 225px;
    text-align: center;
    color: var(--obos-blue);
    font-size: 12px;
    bottom:70px;
    right: -20px;
    line-height: 18px;
}

.chat--input-buttons .icon--tag-blue:hover + .chat--help-box {
    visibility: visible;
}

.chat--search-box {
    box-sizing: border-box;
    position: absolute;
    bottom: 70px;
    right: -20px;
    width: 250px;
    height: auto;
    max-height: 250px;
    overflow: hidden;
    background-color: var(--white);
    border-radius: 20px;
    border: 3px solid var(--obos-blue);
}

.chat--search-box input {
    box-sizing: border-box;
    box-shadow: none;
    border-radius: 20px 20px 0 0;
    width: 100%;
    margin: 0 !important;
}

.chat--search-results-container {
    overflow-y: auto;
    padding-bottom: 10px;
}

.chat--search-results {
    max-height: 180px;
    overflow-y: auto;
}

.chat--search-results p {
    box-sizing: border-box;
    padding-left: 10px;
    height: 30px !important;
    font-weight: bold;
    color: var(--obos-blue);
    cursor: pointer;
    line-height: 30px;
    font-size: 14px;
}

.chat--search-results p:hover {
    color: var(--obos-green);
}

.chat--search-results svg {
    height: 20px;
    width: 20px;
}

/*------------------------------------------------------------------------------------CHAT PAGE ENDS*/
/*------------------------------------------------------------------------------------LOGIN PAGE STARTS*/
/*CONTAINS*/
/*1. LOGIN GENERAL*/
/*2. LOGIN BACKGROUND*/
/*3. LOGIN MESSAGE*/
/*4. LOGIN FORM*/

/*1. LOGIN GENERAL STARTS*/
.login {
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: transparent;
}

.login--content {
    background-color: var(--white);
    border-radius: 0 20px 20px 0;
    position: relative;
    z-index: 1;
    padding: 20px;
}
/*1. LOGIN GENERAL ENDS*/

/*2. LOGIN BACKGROUND STARTS*/
.login--background-image {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    background-image: url('../images/LoginImage.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 0;
}
/*2. LOGIN BACKGROUND ENDS*/

/*3. LOGIN MESSAGE STARTS*/
.login--message {
    height: 100px;
}
/*3. LOGIN MESSAGE ENDS*/

/*4. LOGIN FORM STARTS*/
.login--form {
    width: 100%;
}

.login--form input,
.login--form h2,
.login--message,
.login--form label {
    width: 60%;
}

.login--form *:not(label):not(button) {
    margin-bottom: 40px;
}
/*4. LOGIN FORM ENDS*/
/*------------------------------------------------------------------------------------LOGIN PAGE ENDS*/
/*------------------------------------------------------------------------------------404 PAGE STARTS*/
/*CONTAINS*/
/*1. 404 GENERAL*/
/*2. 404 YOU*/
/*3. 404 MESSAGE*/
/*4. LOGIN FORM*/

/*1. 404 GENERAL STARTS*/
.page-404 {
    color: var(--black);
    text-align: center;
}
/*1. 404 GENERAL ENDS*/

/*2. 404 YOU STARTS*/
.page-404--you {
    margin-top: 100px;
    margin-left: 1000px;
    color: var(--obos-blue);
}

.page-404--you p {
    font-weight: bold;
    margin-bottom: -10px;
    font-size: 14px;
}

.page-404--you svg {
    width: 25px;
    height: 25px;
}
/*2. 404 YOU ENDS*/

/*3. 404 MESSAGE STARTS*/
.page-404--message {
    width: 700px;
    margin-top: 60px;
}

.page-404--message img {
    width: 700px;
}

.page-404--message p {
    width: 650px;
}

.page-404--message p, .page-404--message img {
    margin-bottom: 40px;
}

.page-404--message button:hover {
    color: var(--dark-blue);
    background-color: unset !important;
    background-image: url('~../images/Map.png');
    background-size: cover;
    background-position: center;
}
/*3. 404 MESSAGE ENDS*/
/*------------------------------------------------------------------------------------404 PAGE ENDS*/
/*------------------------------------------------------------------------------------COMPONENTS STARTS*/
/*CONTAINS*/
/*1. HELPING BOX*/
/*2. INFO MESSAGE*/
/*3. DELETE POPUP*/
/*4. INFO BANNER*/
/*5. SMS CODE*/

/*1. HELPING BOX STARTS*/
.helping-box {
    background-color: var(--white);
    border: 3px solid var(--obos-blue);
    border-radius: 20px;
    padding: 8px;
    color: var(--obos-blue);
    text-align: center;
    width: 120px;
    position: absolute;
    z-index: 100000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease;
    height: fit-content;
    right: 50px;
}

.helping-box p {
    font-size: 14px !important;
    margin: 0 !important;
}

.lock-button:hover + .helping-box,
.map--add-form-header button:hover + .helping-box {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease;
}

.helping-box--lock {
    margin-top: 5px;
    margin-right: 300px;
}

.helping-box--coordinates {
    margin-top: 50px;
    margin-right: 22px;
}

.helping-box--draw {
    margin-top: 50px;
    margin-right: 12px;
}

.helping-box--draw-poly {
    width: 151px !important;
    margin-top: 50px;
}

/*1. HELPING BOX ENDS*/
/*2. INFO MESSAGE STARTS*/
.info-message {
    height: 60px;
    width: 100%;
    border-radius: 20px;
}

.info-message svg {
    min-width: 40px;
    min-height: 40px;
    height: 40px;
    width: 40px;
    margin-right: 10px;
    margin-left: 10px;
}

.info-message p {
    margin: auto 0;
}

.info-message-info {
    background-color: var(--light-blue);
    color: var(--dark-blue);
}

.info-message-warning {
    background-color: var(--light-yellow);
    color: var(--dark-yellow);
}

.info-message-error {
    background-color: var(--light-red);
    color: var(--dark-red);
}

.info-message-success {
    background-color: var(--light-green);
    color: var(--dark-green);
}
/*2. INFO MESSAGE ENDS*/
/*3. DELETE POPUP STARTS*/
.delete-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

.popup-opacity-background, .delete-popup--background-info {
    background: rgba(0, 0, 0, 0.5);
}

.delete-popup-content {
    background: var(--white);
    width: 500px !important;
    min-height: fit-content;
    max-height: 500px;
    padding: 30px;
    border-radius: 20px;
    text-align: center;

}

.delete-popup-content p {
    margin: 20px 0;
    color: var(--black);
}

.delete-popup-content button {
    font-size: 16px;
    margin: 10px;
    padding: 0 !important;
    width: 105px !important;
}

.delete-popup--relationships {
    text-align: left;
}

.delete-popup--relationships p {
    font-weight: bold;
    margin-top: 0;
}
/*3. DELETE POPUP ENDS*/
/*4. INFO BANNER STARTS*/
.info-banner {
    position: absolute;
    top: 0;
    left: 0;
    height: 180px;
    width: 100%;
    z-index: 5000;
    color: var(--black);
    border-radius: 0 0 20px 20px;
    background-color: var(--light-yellow);
}

.info-banner--img {
    width: 150px;
    padding-bottom: 30px;
}

.info-banner--img img {
    height: 150px;
    width: 150px;
}

.info-banner--content {
    width: calc(100vw - 200px);
    padding: 30px 40px 40px 20px;
}

.info-banner--text {
    padding-left: 20px;
}

.info-banner--text h3 {
    margin-bottom: 10px;
}

.info-banner--close-btn {
    font-size: 14px;
    cursor: pointer;
    color: var(--dark-yellow);
    height: fit-content;
    filter: brightness(110%);
    width: 55px;
    text-wrap: nowrap;
}

.info-banner--close-btn:hover {
    filter: brightness(70%);
}

.info-banner--christmas {
    background-color: var(--red);
    color: var(--white) !important;
}
.info-banner--christmas .info-banner--close-btn {
    color: var(--white) !important;
}

.info-banner--christmas .info-banner--img {
    padding: 0;
    margin: 10px;
}

.info-banner--christmas .info-banner--close-btn:hover {
    filter: unset;
}

/*4. INFO BANNER ENDS*/
/*5. SMS CODE STARTS*/
.sms-code--form {
    width: 100%;
}
.sms-code--inputs input {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    font-size: 20px;
    text-align: center;
    margin-bottom: 50px;
    padding: 0!important;
}

.sms-code--form .input-hidden {
    position: absolute;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: 0 !important;
    border: 0 !important;
    box-shadow: none !important;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
}

.sms-code--form .input-hidden:focus {
    border: 3px solid white !important;
}

.sms-code--form.login {
    width: 75%;
    height: 410px;
}

.sms-code--form.login h2 {
    margin-bottom: 30px;
    width: 75%;
}

.sms-code--form.login .sms-code--inputs {
    width: 100%;
}

/*5. SMS CODE ENDS*/

/*6. FEEDBACKS*/
.feedback--header {
    margin: 0 13px;
}

.feedback--header p:first-child, .feedback--data .feedback p:first-child  {
    padding-left: 10px;
}
.feedback--header p, .feedback--data p {
    width: 150px;
}

.feedback--header p:nth-child(2), .feedback--data p:nth-child(2) {
    width: calc(100% - 600px);
}

.feedback--data {
    height: calc(60px * 6);
    overflow-y: scroll;
    width: 100%;;
}

.feedback--data .feedback {
    box-sizing: border-box;
    height: 60px;
    margin: 10px;
}

.feedback--data .feedback.positiv p:first-child,
.feedback--data .feedback.bug p:first-child,
.feedback--data .feedback.forslag p:first-child,
.feedback--data .feedback.kritisk p:first-child
{
    font-weight: 700;
}

.feedback--data .feedback.positiv {
    border: 3px solid var(--obos-green);
}
.feedback--data .feedback.positiv p:first-child {
    color: var(--obos-green);
}

.feedback--data .feedback.bug {
    border: 3px solid var(--yellow);
}
.feedback--data .feedback.bug p:first-child {
    color: var(--yellow);
}

.feedback--data .feedback.forslag {
    border: 3px solid var(--obos-blue);
}
.feedback--data .feedback.forslag p:first-child {
    color: var(--obos-blue);
}

.feedback--data .feedback.kritisk {
    border: 3px solid var(--red);
}
.feedback--data .feedback.kritisk p:first-child {
    color: var(--red);
}

.feedback--data .feedback-none {
    width: 300px;
}

/*7. CHOOSE PORTS*/
.choose-port--background {
    position: absolute;
    width: calc(100% - 24vw);
    height: calc(100% - 85px);
    top: 90px;
    left: 24vw;
    z-index: 0;
}

.choose-port--message {
    background-color: var(--white);
    border-radius: 20px;
    width: 500px;
    height: 450px;
}

.choose-port--message p:not(.dropdown-item, .choose-port--chosen-ports .port), .choose-port--message select, .choose-port--message h3 {
    margin-bottom: 30px;
}

.choose-port--message p, .choose-port--message select {
    box-sizing: border-box;
    width: 400px;
}

.choose-port--message strong {
    color: var(--obos-blue);
}

.choose-port--message form p:first-of-type {
    margin: 0 !important;
}

.choose-port--chosen-ports {
    box-sizing: border-box;
    height: 50px;
    border-radius: 20px;
    border: 3px solid var(--white);
    padding-left: 10px;
    background-color: var(--white);
    margin-bottom: 20px;
    width: 415px;
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    align-items: center;
}

.choose-port--chosen-ports .port {
    display: inline-block;
}

.choose-port--chosen-ports .port:hover {
    text-decoration: line-through;
    cursor: pointer;
}

.choose-port--select {
    width: 100%;
}

.choose-port--select button {
    height: 50px;
    margin-bottom: 20px !important;
}

.choose-port--select .custom-select .dropdown-list {
    bottom: 80px;
}

/*Custom select*/

.custom-select {
    position: relative;
}

.custom-select .dropdown-list {
    position: absolute;
    bottom: 60px;
    box-sizing: border-box;
    background-color: var(--white);
    border-radius: 20px;
    border: solid 3px var(--obos-blue);
    padding: 10px;
    width: 200px;
    max-height: 200px;
    overflow-y: scroll;
}

.custom-select .dropdown-item {
    color: var(--obos-blue);
    font-weight: bold;
    cursor: pointer;
}

.custom-select .dropdown-item:hover {
    color: var(--obos-green);
}

.custom-select .dropdown-item.disabled {
    cursor: not-allowed;
    color: var(--disabled-blue);
}

/*------------------------------------------------------------------------------------COMPONENTS ENDS*/